import { FC, ReactNode, createContext, useContext, useRef } from 'react';
import { useStore } from 'zustand';
import { createStore } from 'zustand/vanilla';

export type DropzoneState = {
  isWindowDragActive: boolean;
};

type Action = {
  updateIsWindowDragActive: (isWindowDragActive: boolean) => void;
};

type TStore = ReturnType<typeof createDropzoneStore>;

const DropzoneStoreContext = createContext<TStore | null>(null);

const createDropzoneStore = () => {
  return createStore<DropzoneState & Action>()((set) => ({
    isWindowDragActive: false,
    updateIsWindowDragActive: (isWindowDragActive) =>
      set((store) => {
        if (store.isWindowDragActive === isWindowDragActive) {
          return store; // Не изменяем объект, если состояние то же самое
        }
        return { ...store, isWindowDragActive };
      }),
  }));
};

const useDropzoneStore = () => {
  const store = useContext(DropzoneStoreContext);

  if (!store) {
    throw new Error('useDropzoneStore must be used within a DropzoneProvider');
  }

  return store;
};

const useStoreMethods = () => {
  const store = useDropzoneStore();

  const updateIsWindowDragActive = useStore(store, (s) => s.updateIsWindowDragActive);

  return {
    updateIsWindowDragActive,
  };
};

export const DropzoneProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const storeRef = useRef<TStore>();

  if (!storeRef.current) {
    storeRef.current = createDropzoneStore();
  }

  return (
    <DropzoneStoreContext.Provider value={storeRef.current}>
      {children}
    </DropzoneStoreContext.Provider>
  );
};

const dropzoneModel = {
  useStore: useDropzoneStore,
  useStoreMethods,
};

export default dropzoneModel;
